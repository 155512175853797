import React from 'react';
import { Link } from 'gatsby';
//import Nav from './Nav';
import { StaticImage } from "gatsby-plugin-image"

export default function Header() {
  return (
    <header className="header">
      <div className="header__container">
      <Link className="header__logo" to={`/binges`}>
        <StaticImage src="../assets/images/logo2.png" alt="BingePlan" />
      </Link>
      </div>
    </header>
  );
}