import React from "react";
import Layout from './src/components/Layout';
import "./src/styles/base.scss";

export function onInitialClientRender() {
    console.log("ReactDOM.render has executed");
}

export function wrapPageElement({ element, props }) {
    return <Layout {...props}>{element}</Layout>;
}
/*
export function wrapRootElement({ element }) {
    return <React.StrictMode>{element}</React.StrictMode>;
}
*/