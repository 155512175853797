import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <p>&copy; BingePlan {new Date().getFullYear()} | GoTo11 Media</p>
        <p>Made possible by <a href="http://www.omdbapi.com/" target="_blank" rel="noreferrer">OMDB API</a></p>
      </div>
    </footer>
  );
}